import React, { useEffect } from "react";

const GoogleAds = () => {
  useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [].push({});
  }, []);
  return (
    <>
      <ins
        className="adsbygoogle"
        style={{display:"block",width:"100%"}}
        data-ad-format="fluid"
        data-ad-layout-key="-fb+5w+4e-db+86"
        data-ad-client="ca-pub-5920808639602708"
        data-ad-slot="7859020867"
        data-adtest="on"
      ></ins>
    </>
  );
};

export default GoogleAds;
